<template>
  <div class="bg-[#16131B] h-[60px] lg:h-[72px] w-full flex justify-between items-center px-6 py-3">
    <!-- Logo -->
    <div>
      <a :href="goToWebsite()">
        <img src="@/assets/svgs/ohbb_logo.svg" class="w-[36px]">
      </a>
    </div>
    <!-- Navigation -->
    <div class="flex-row items-center gap-8 text-[#9C93B3] font-['Rubik'] text-sm font-[400] lg:flex hidden">
      <div class="dropdown" ref="dropdown">
        <div class="flex flex-row items-center cursor-pointer hover:text-[#EFEDF2]" :onclick="clickOBK">
          Oh Baby! Kart
          <img src="@/assets/images/homepage/arrow-filled-down.svg" alt="chevron" class="w-3 h-3 ml-1"
               :class="OBKVisibility === 'flex' ? 'rotate-180 transform transition-transform' : 'transform transition-transform'">
        </div>
        <div class="w-[129px] absolute z-10 bg-[#322C3F] rounded-lg mt-[26px]" :class="OBKVisibility">
          <div class="text-[#EFEDF2] font-['Rubik'] text-sm font-[400] flex flex-col py-2">
            <a :href="goToWebsite('/overview')" class="mx-[16px] my-[10px]">How to play</a>
            <a :href="goToWebsite('/leaderboard/oh-baby-kart')" class="mx-[16px] my-[10px]">Leaderboards</a>
            <a :href="goToWebsite('/twitch-drops')" class="mx-[16px] my-[10px]">Twitch Drops</a>
            <a :href="goToWebsite('/characters')" class="mx-[16px] my-[10px]">Characters</a>
            <a :href="goToWebsite('/stages')" class="mx-[16px] my-[10px]">Stages</a>
            <!-- <a href="/karts" class="mx-[16px] my-[10px]">Karts</a> -->
          </div>
        </div>
      </div>

      <!-- <a href="/leaderboard/oh-baby-kart" class="hover:text-[#EFEDF2]">Leaderboards</a> -->
      <a :href="goToWebsite()" class="hover:text-[#EFEDF2]">Website</a>
      <a :href="goToWebsite('/shogun-curse')" class="hover:text-[#EFEDF2] cursor-pointer">Shogun Curse</a>
      <a :href="goToWebsite('/bam-bam-boom')" class="hover:text-[#EFEDF2] cursor-pointer">Bam Bam Boom</a>
      <a :href="goToWebsite('/news')" class="hover:text-[#EFEDF2]">News</a>
      <a :href="goToWebsite('/support')" class="hover:text-[#EFEDF2]">Support</a>

      <div class="dropdown" ref="dropdown">
        <div class="flex flex-row items-center cursor-pointer hover:text-[#EFEDF2]" :onclick="clickLM">
          Learn More
          <img src="@/assets/images/homepage/arrow-filled-down.svg" alt="chevron" class="w-3 h-3 ml-1"
               :class="LMVisibility === 'flex' ? 'rotate-180 transform transition-transform' : 'transform transition-transform'">
        </div>
        <div class="w-[129px] absolute z-10 bg-[#322C3F] rounded-lg mt-[26px]" :class="LMVisibility">
          <div class="text-[#EFEDF2] font-['Rubik'] text-sm font-[400] flex flex-col py-2">
            <a :href="goToWebsite('/about-us')" class="mx-[16px] my-[10px]">Company</a>
            <a :href="goToWebsite('/careers')" class="mx-[16px] my-[10px]">Work With Us</a>
            <a :href="goToWebsite('/developers')" class="ml-[16px] my-[10px]">Partner With Us</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Account/Play/Hamburger -->
    <div class="flex flex-row items-center gap-6">
      <div class="dropdown" ref="dropdown">
        <div class="flex flex-row items-center gap-2 cursor-pointer" :onclick="clickAccount">
          <div class="bg-[#483F5A] w-8 h-8 flex items-center justify-center rounded-[100px]">
            <img :src="userData ? userData.image : pfpSvg" class="rounded-full">
          </div>
          <div class="flex flex-row items-center" v-if="userToken">
            <p class="text-sm font-normal truncate text-secText font-Rubik">{{ userData ? userData.displayName : "" }}
            </p>
            <img src="@/assets/images/homepage/arrow-filled-down.svg" alt="chevron" class="w-3 h-3 ml-1"
                 :class="LoginVisibility === 'flex' ? 'rotate-180 transform transition-transform' : 'transform transition-transform'">
          </div>
        </div>

        <div
            class="w-[129px] absolute z-10 bg-[#322C3F] rounded-lg mt-[20px] lg:right-[1%] xl:right-[1%] right-[5%] xxxl:right-[1%]"
            :class="LoginVisibility" v-if="!userToken">
          <div class="text-[#EFEDF2] font-['Rubik'] text-sm font-[400] flex flex-col py-2">
            <a :onclick="login" class="mx-[16px] my-[10px] cursor-pointer">Login</a>
            <a :onclick="signup" class="mx-[16px] my-[10px] cursor-pointer">Sign Up</a>
          </div>
        </div>

        <div
            class="w-[250px] absolute z-10 bg-[#322C3F] rounded-lg mt-[20px] lg:right-[1%] xl:right-[1%] right-[5%] sm:right-[7%] xxxl:right-[1%]"
            :class="LoginVisibility" v-if="userToken">
          <div class="text-[#EFEDF2] font-['Rubik'] text-sm font-[400] flex flex-col w-full py-2">
            <div class="flex flex-row items-center gap-2 border-b-[1px] border-[#483f5a] pt-2 px-4 pb-[20px]">
              <div class="bg-[#483F5A] w-8 h-8 flex items-center justify-center rounded-[100px] cursor-pointer"
                   :onclick="clickAccount">
                <img :src="userData ? userData.image : pfpSvg" class="rounded-full cursor-default" />
              </div>
              <div class="max-w-[170px] overflow-hidden">
                <h1 class="text-base font-medium text-left truncate text-mainText font-Rubik">
                  {{ userData ? userData.displayName : "" }}
                </h1>
                <p class="text-xs font-normal text-left truncate text-secText font-Rubik">
                  {{ userData ? userData.address : "" }}
                </p>
              </div>
            </div>

            <!-- <div
                  class="px-4 pt-[10px] pb-2 border-b-[1px] border-[#483f5a] flex flex-row items-center"
                >
                  <h1
                    class="font-Rubik text-secText text-xs font-medium tracking-[1.5px] uppercase text-left"
                  >
                    OBK FOUNDERS Package Purchased
                    {{
                      userData
                        ? userData.founders_package
                          ? userData.founders_package.times_purchased
                          : "0"
                        : "0"
                    }}/{{
                      userData
                        ? userData.founders_package
                          ? userData.founders_package.purchase_limit
                          : "1"
                        : "1"
                    }}
                  </h1>
                </div> -->

            <div v-if="userData && userData.can_access_game"
                 class="px-4 py-[12px] border-b-[1px] border-[#483f5a] flex flex-row items-center">
              <div class="flex flex-row items-center gap-2 cursor-pointer w-[100%]">
                <button @click="goToLearnMore" class="primary w-[100%] h-[32px] py-[10px] px-[12px] button-small">
                  Download OBB Platform
                </button>
              </div>
            </div>

            <!-- <div
                  v-if="userData && userData.can_access_game"
                  class="px-4 py-1 border-b-[1px] border-[#483f5a] flex flex-row items-center">
                  <div class="flex flex-row items-center gap-2 cursor-pointer" @click="claimSteamKey">
                    <img src="@/assets/icons/obb_logo.svg" class="w-[20px] h-[20px]" />
                    <h1 class="font-Rubik py-[10px] text-sm font-normal">Claim Your Beta Access!</h1>
                  </div>
                </div> -->

            <!-- <div class="px-4 py-1 border-b-[1px] border-[#483f5a] flex flex-row items-center">
                      <div class="flex flex-row items-center gap-2 cursor-pointer">
                          <img src="@/assets/icons/settings.svg" class="w-[20px] h-[20px]">
                          <h1 class="font-Rubik py-[10px] text-sm font-normal">Change password</h1>
                      </div>
                      </div> -->

            <div class="px-4 py-1 border-b-[1px] border-[#483f5a] flex flex-row items-center">
              <div class="flex flex-row items-center gap-2 cursor-pointer" :onclick="goToSettings">
                <img src="@/assets/icons/settings.svg" class="w-[20px] h-[20px]">
                <h1 class="font-Rubik py-[10px] text-sm font-normal">Account Settings</h1>
              </div>
            </div>

            <div class="flex flex-row items-center px-4 py-1">
              <div class="flex flex-row items-center gap-2 cursor-pointer" :onclick="logout">
                <img src="@/assets/icons/logout.svg" class="w-[20px] h-[20px]" />
                <h1 class="font-Rubik py-[10px] text-sm font-normal">Log out</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PLAY NOW -->
      <!-- <div class="w-[102px] h-12 flex-col justify-start items-start gap-2 cursor-pointer lg:inline-flex hidden">
        <div class="self-stretch px-[1px] py-[1px] rounded-lg shadow bg-gradient-to-b to-[#950325] from-[#fb5e83] justify-center items-center gap-2 inline-flex" @click="gotoPackage">
          <div class="text-center font-['Rubik'] text-[#FEE6EC] font-[500] text-base bg-gradient-to-b from-[#eb063d] to-[#950325] px-4 py-3 rounded-lg whitespace-nowrap">Play Now</div>
        </div>
      </div> -->

      <!-- Hamburger Menu -->
      <!-- <div class="lg:hidden" @click="toggleHamburgerMenu">
        <img :src="HamburgerMenuIcon" alt="chevron" class="w-5 h-5">
      </div> -->
      <div class="hamburger hamburger--vortex lg:hidden" :class="showMenu ? 'is-active' : ''"
           @click="toggleHamburgerMenu">
        <div class="hamburger-box">
          <div class="hamburger-inner"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Hamburger menu content -->
  <div class="w-full bg-[#16131B] absolute lg:hidden z-[10]" v-if="showMenu">
    <div class="text-[#9C93B3] font-['Rubik'] text-base font-[600] flex flex-col">
      <div class="py-[10px] px-[32px] flex flex-row items-center justify-between cursor-pointer" @click="clickOBK"
           :class="OBKVisibility === 'flex' ? 'bg-[#322C3F] shadow-[inset_#ED063D_2px_0px_0px_0px]' : 'bg-[#16131B]'">
        Oh Baby! Kart
        <img src="@/assets/images/homepage/arrow-filled-down.svg" alt="chevron" class="w-4 h-4 ml-1"
             :class="OBKVisibility === 'flex' ? 'rotate-180 transform transition-transform' : 'transform transition-transform'">
      </div>
      <div class="flex flex-col bg-[#0E0D12] px-[32px]" :class="OBKVisibility">
        <a :href="goToWebsite('/overview')" class="my-[10px]">How to play</a>
        <a :href="goToWebsite('/leaderboard/oh-baby-kart')" class="my-[10px]">Leaderboards</a>
        <a :href="goToWebsite('/twitch-drops')" class="my-[10px]">Twitch Drops</a>
        <a :href="goToWebsite('/characters')" class="my-[10px]">Characters</a>
        <a :href="goToWebsite('/stages')" class="py-[10px]">Stages</a>
        <!-- <a href="/karts" class="py-[10px]">Karts</a> -->
      </div>

      <!-- <a href="/leaderboard/oh-baby-kart" class="py-[10px] px-[32px]">Leaderboards</a> -->
      <a :href="goToWebsite()" class="py-[10px] px-[32px] cursor-pointer">Website</a>
      <a :href="goToWebsite('/shogun-curse')" class="py-[10px] px-[32px] cursor-pointer">Shogun Curse</a>
      <a :href="goToWebsite('/bam-bam-boom')" class="py-[10px] px-[32px] cursor-pointer">Bam Bam Boom</a>
      <a :href="goToWebsite('/news')" class="py-[10px] px-[32px]">News</a>
      <a :href="goToWebsite('/support')" class="py-[10px] px-[32px]">Support</a>

      <div class="py-[10px] px-[32px] flex flex-row items-center justify-between cursor-pointer" @click="clickLM"
           :class="LMVisibility === 'flex' ? 'bg-[#322C3F] shadow-[inset_#ED063D_2px_0px_0px_0px]' : 'bg-[#16131B]'">
        Learn More
        <img src="@/assets/images/homepage/arrow-filled-down.svg" alt="chevron" class="w-4 h-4 ml-1"
             :class="LMVisibility === 'flex' ? 'rotate-180 transform transition-transform' : 'transform transition-transform'">
      </div>
      <div class="flex flex-col bg-[#0E0D12] px-[32px]" :class="LMVisibility">
        <a :href="goToWebsite('/about-us')" class="py-[10px]">Company</a>
        <a :href="goToWebsite('/careers')" class="py-[10px]">Work With Us</a>
        <a :href="goToWebsite('/developers')" class="py-[10px]">Partner With Us</a>
      </div>
    </div>

    <!-- <div class="w-full px-4 pt-[10px] mb-[25px] h-12 flex-col justify-start items-start gap-2 cursor-pointer inline-flex lg:hidden" v-if="!userToken">
      <div class="self-stretch px-[1px] py-[1px] rounded-lg shadow bg-gradient-to-b to-[#950325] from-[#fb5e83] justify-center items-center gap-2 inline-flex" @click="joinWaitlist">
        <div class="w-full text-center font-['Rubik'] text-[#FEE6EC] font-[700] text-[16px] leading-6 bg-gradient-to-b from-[#eb063d] to-[#950325] px-4 py-3 rounded-lg">Register now</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import HamburgerMenu from '@/assets/icons/hamburger_menu.svg'
import CloseIcon from '@/assets/icons/close.svg'
import personSvg from '@/assets/icons/person.svg'

import envConfig from '../config'
import axios from 'axios'

import './hamburger.css'

export default {
  components: {  },

  data() {
    return {
      OBKVisibility: 'hidden',
      LMVisibility: 'hidden',
      LoginVisibility: 'hidden',
      showMenu: false,
      userToken: null,
      userData: null,
      allow_store: false,
    }
  },

  mounted() {
    this.userToken = window.getCookie('obb_ck')
    this.fetchUser()
  },

  methods: {
    clickOBK() {
      if (this.OBKVisibility === 'hidden') {
        this.LMVisibility = 'hidden'
        this.LoginVisibility = 'hidden'
        this.OBKVisibility = 'flex'
      } else this.OBKVisibility = 'hidden'
    },

    clickLM() {
      if (this.LMVisibility === 'hidden') {
        this.OBKVisibility = 'hidden'
        this.LoginVisibility = 'hidden'
        this.LMVisibility = 'flex'
      } else this.LMVisibility = 'hidden'
    },

    clickAccount() {
      if (this.LoginVisibility === 'hidden') {
        this.OBKVisibility = 'hidden'
        this.LMVisibility = 'hidden'
        this.LoginVisibility = 'flex'
      } else this.LoginVisibility = 'hidden'
    },

    toggleHamburgerMenu() {
      this.showMenu = !this.showMenu
      this.LMVisibility = 'hidden'
      this.OBKVisibility = 'hidden'
    },

    joinWaitlist() {
      if (!this.userToken) {
        window.location.href = envConfig.websiteUrl + `/login?signup`
      } else if (this.userToken) {
        window.location.href = envConfig.websiteUrl + `/profile`
      }
    },

    login() {
      window.location.href = `${envConfig.websiteUrl}/login?from=store`;
    },

    logout() {
      let cookieValue = this.userToken;
      let expDate = new Date();
      expDate.setFullYear(expDate.getFullYear() - 1);
      document.cookie = "obb_ck=" + cookieValue + ";expires=" + expDate
          + (window.location.hostname !== 'localhost'?";domain=.ohbabygames.com":"") + ";path=/";

      window.location.href = '/';
    },

    signup() {
      window.location.href = envConfig.websiteUrl + `/login?signup`;
    },

    gotoStore() {
      window.location.href = '/';
    },

    fetchUser() {
      const url = envConfig.baseUrl + '/api/user';

      if (this.userToken) {
        axios.get(url, {
          headers: {
            'Authorization': `Bearer ${this.userToken}`,
            'Content-Type': 'application/json',
          }
        })
            .then(response => {
              const data = response.data;
              this.userData = data.data
              if (this.userData.allow_store) {
                this.allow_store = true;
              }
            })
            .catch(error => {
              console.log(error)
            });
      }
    },

    goToLearnMore() {
      window.location.href = envConfig.websiteUrl + "/download-platform";
    },

    goToSettings() {
      window.location.href = envConfig.websiteUrl + "/account-settings";
    },

    claimSteamKey() {
      window.location.href = envConfig.websiteUrl + "/profile";
    },

    goToWebsite(relPath) {
      if (relPath) {
        return envConfig.websiteUrl + relPath;
      } else {
        return envConfig.websiteUrl;
      }
    },
  },

  computed: {
    HamburgerMenuIcon() {
      return this.showMenu ? CloseIcon : HamburgerMenu
    },
    pfpSvg() {
      return personSvg
    },
  }
}
</script>
